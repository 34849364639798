$b_sm: 768px;
$b_md: 992px;
$b_lg: 1200px;


@import "../../src/style/vendor/mixins.scss";


body {
  font-weight: 400;
  /* color: #666; */
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  background-color: #ebebeb;
  font-family: 'Open Sans';
}



.svg {
  width: inherit;
  height: inherit;
  display: block;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.jelly{
  &__nav{
    .row {
      position: relative;
    }
    $h: 126;
    .-search {
      position: static;
      display: inline-flex;
      width: tos(146);
      @media (max-width: $b_lg) {
        border-bottom: 1px solid #c7c7c7;
      }

      form.is-active {
        input {
          //opacity: 1;
          pointer-events: auto;
          width: calc(100% - #{tos(146)});
          padding: 0 tos(68);
          border-right: 1px solid #c7c7c7;
        }
      }

      input {
        //opacity: 0;
        pointer-events: none;
        position: absolute;

        background: #fff;
        left: tos(146);
        width: 0;


        color: #000;


        font-weight: 100;
        font-size: tos(42);


        height: tos($h);
        line-height: tos($h);

        padding: 0;

        outline: none;
        border: 0;


        appearance: normal;
        transition: .3s;

        box-sizing: border-box;


        &::-webkit-input-placeholder {
          color: #e2e3e4;
        }
        &:focus {
          //border-color: #A3D680;
        }
      }
      *[type=submit] {
        outline: none;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        border-radius: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border: 0;
        background: transparent;
        padding: 0;
        margin: 0;

        width: tos(146);
        height: tos(126);
        border-left: 1px solid #c7c7c7;
        border-right: 1px solid #c7c7c7;

        display: block;

        &:hover {
          .svg {
            svg {
              fill: #000;
            }
          }
        }

        .svg {
          margin: auto;
          width: tos(56);
          height: tos(54);
          svg {
            fill: #969696;
            transition: .3s;
          }
        }
      }

      &__xs {
        width: 100%;
        position: relative;
        form {
          width: 100%;
          input {
            pointer-events: auto;
            width: calc(100% - #{tos(146)} + 1px );
            @media (max-width: $b_sm) {
              width: calc(100% - #{tos(146)} );
            }
            border-right: 1px solid #c7c7c7;

            padding: 0 tos(68);
            z-index: 20;
          }
          *[type=submit] {
            z-index: 10;
            margin-right: auto;
          }
        }

      }
    }

    box-sizing: content-box;
    background-color: rgba(255,255,255,0.8);
    border-top: 1px solid #c7c7c7;
    border-bottom: 1px solid #c7c7c7;


    min-height: tos($h);

    &__menu {
      position: relative;
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;

      a {
        &:hover {
          text-decoration: none;
        }
        &:focus {
          outline: 0;
          text-decoration: none;
        }
      }

      @media (max-width: $b_md) {
        flex-wrap: wrap;
        border-left: 1px solid #c7c7c7;
      }

      > li {
        position: relative;

        &.-dropdown {
          > a {
            &:before {
              content: '';
              width: 0;
              height: 0;
              border-left: tos(8) solid transparent;
              border-right: tos(8) solid transparent;
              border-top: tos(18) solid #000;
              margin: tos(4) 0 0 tos(28);
            }

          }
          &.js-active {
            > ul {
              //max-height: 500px;
              pointer-events: auto;
              //overflow: visible;
              opacity: 1;
              top: 100%;
            }
          }

          > ul {
            top: 0;
            opacity: 0;
            transition: .3s;
            //max-height: 0;
            //overflow: hidden;
            pointer-events: none;
            //visibility: hidden;
            background-color: #fff;
            list-style: none;
            position: absolute;
            z-index: 999;
            min-width: 100%;
            padding: 0;
            text-align: right;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            >li {
              &:first-child {
                margin-top: tos(70);
              }
              &:last-child {
                margin-bottom: tos(70);
              }

              &.-dropdown {
                > a {
                  &:after {
                    position: absolute;
                    left: tos(54);
                    top: tos(42);
                    content: '';
                    width: 0;
                    height: 0;
                    border-top: tos(10) solid transparent;
                    border-right: tos(16) solid #000;
                    border-bottom: tos(10) solid transparent;
                  }
                }
                &.js-active {
                  > ul {
                    opacity: 1;
                    pointer-events: auto;
                    &.js-left {
                      right: 100%;
                    }
                    &.js-right {
                      right: auto;
                      left: 100%;
                    }
                    @media (max-width: $b_sm) {
                      display: block!important;
                    }
                  }
                }

                > ul {
                  transition: .3s;
                  pointer-events: none;
                  opacity: 0;
                  position: absolute;
                  top: 0;
                  &.js-left {
                    right: 50%;
                  }
                  &.js-right {
                    left: 50%;
                  }
                  background-color: #fff;
                  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
                  padding: 0;
                  min-height: 100%;
                  //min-width: tos(1395);
                  padding-top: tos(70);
                  padding-bottom: tos(70);
                  box-sizing: border-box;

                  @media (max-width: $b_md) {
                    max-width: 100%!important;
                    min-width: 100%!important;
                  }
                  @media (max-width: $b_sm) {
                    position: relative;
                    left: 0!important;
                    right: 0!important;
                    display: none!important;
                  }

                  &.-decore {
                    min-width: tos(1395);
                    padding-bottom: tos(520);
                    > li:last-child {
                      position: absolute;
                      height: tos(480);
                      overflow: hidden;
                      position: absolute;
                      left: 0;
                      right: 0;
                      bottom: 0;

                      img {
                        display: block;
                        height: 100%;
                      }
                    }

                  }
                  > li {
                    display: flex;
                    > a {
                      white-space: nowrap;
                      display: inline-block;

                      &:before {
                        display: inline-block;
                        margin-left: tos(24);
                        top: tos(6);
                        position: relative;
                        content: '';
                        width: 0;
                        height: 0;
                        border-top: tos(10) solid transparent;
                        border-right: tos(16) solid #000;
                        border-bottom: tos(10) solid transparent;
                      }

                      @media (max-width: $b_md) {

                        white-space: normal;
                        &:first-child {
                          width: 60%!important;
                        }
                        &:last-child {
                          width: 40%!important;
                        }

                        &:before {
                          position: absolute!important;
                          right: 7px;
                          top: 17px;
                        }
                      }
                    }
                  }
                }
              }
            }
            a {
              white-space: nowrap;
              transition: .3s;
              padding: tos(20) tos(54);
              color: #626262;
              font-size: tos(32);
              display: block;
              position: relative;

              &:hover {
                background-color: #ebebeb;
                //font-weight: bold;
              }
              span {
                display: inline-block;
                vertical-align: middle;

              }
              .svg {
                width: tos(42);
                height: tos(42);
                margin-left: tos(50);
              }
            }
          }
        }

        &.-color{
          &:after {
            content: '';
            position: absolute;
            width: 80%;
            height: tos(15);
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background-color: #fff;
          }
          &-blue{
            ul {
              a:hover {
                color: #56afca;
              }
              svg {
                fill: #56afca;
              }
            }
            .-dropdown {
              a {
                &:after {
                  border-right: tos(16) solid #56afca!important;
                }
              }
              >ul {
                > li > a:before {
                  border-right: 6.37px solid #56afca!important;
                }
              }
            }
            &:after {
              background-color: #56afca;
            }
          }
          &-yellow{
            ul{
              a:hover {
                color: #e89e3b;
              }
              svg {
                fill: #e89e3b;
              }
            }
            .-dropdown {
              a {
                &:after {
                  border-right: tos(16) solid #e89e3b!important;
                }
              }
              >ul {
                > li > a:before {
                  border-right: 6.37px solid #e89e3b!important;
                }
              }
            }
            &:after {
              background-color: #e89e3b;
            }
          }
          &-green{
            ul{
              a:hover {
                color: #86b754;
              }
              svg {
                fill: #86b754;
              }
            }
            .-dropdown {
              a {
                &:after {
                  border-right: tos(16) solid #86b754!important;
                }
              }
              >ul {
                > li > a:before {
                  border-right: 6.37px solid #86b754!important;
                }
              }
            }
            &:after {
              background-color: #86b754;
            }
          }
          &-red{
            ul {
              a:hover {
                color: #d54d4c;
              }
              svg {
                fill: #d54d4c;
              }
            }
            .-dropdown {
              a {
                &:after {
                  border-right: tos(16) solid #d54d4c!important;
                }
              }

              >ul {
                > li > a:before {
                  border-right: 6.37px solid #d54d4c!important;
                }
              }
            }
            &:after {
              background-color: #d54d4c;
            }
          }
        }
        &:not(.-search) {
          flex: 1;
          text-align: center;

          border-right: 1px solid #c7c7c7;

          @media (max-width: $b_md) {
            flex: 0 0 50%;
            height: tos($h);
          }

          @media (max-width: $b_sm) {
            flex: 0 0 100%;
            height: tos($h);
          }


          > a {
            font-weight: 100;
            font-family: 'Open Sans';
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            font-size: tos(42);
            color: #000;
            &:hover {
              text-decoration: none;
            }

            @media (max-width: $b_md) {
              font-size: 12px;

            }
          }
        }

        .-icon__amazon {
          width: tos(260);
          height: tos(66);
          margin-right: tos(26);
        }


      }

    }

  }

  &__info{
    .row {
      position: relative;
    }
    &__block{
      overflow: hidden;
      background-color: #fff;
      padding-top: tos(32);
      padding-bottom: tos(32);

      &--decore {
        position: absolute;
        height: tos(80);
        left: 16px;
        right: 16px;
        bottom: 0;
        z-index: -1;
        //background-color: #00a2ff;
        box-shadow: 0 0 20px;
        &:before {
          content: '';
          height: 100%;
          width: 40px;
          position: absolute;
          left: -2px;
          bottom: 0;
          box-shadow: 0 0 20px;
          transform: skewX(-10deg);
          opacity: .3;
        }
        &:after {
          content: '';
          height: 100%;
          width: 40px;
          position: absolute;
          right: -2px;
          bottom: 0;
          box-shadow: 0 0 20px;
          transform: skewX(10deg);
          opacity: .3;
        }
      }
      &__filter {
        text-align: center;
        border-right: 1px solid #c7c7c7;
        padding-bottom: tos(140);

        &--title{
          span {
            display: inline-block;
            vertical-align: middle;
          }
          .svg {
            svg {
              fill: #57b6d3;
            }
            margin-left: tos(16);
            width: tos(48);
            height: tos(42);
          }
        }

        &--wrap {
          margin-top: tos(40);

          &__left {
            width: 32%;
            //display: inline-flex;
            text-align: left;
          }
          &__right {
            flex: 1;

            display: flex;
            justify-content: space-between;
            > div {
              display: inline-block;
              vertical-align: middle;


            }
          }
          display: flex;
          flex-direction: row-reverse;

          justify-content: space-between;
          padding: 0 tos(66);
          > div {

          }
          .-symbol {
            margin-right: tos(14);
          }
          input.-btn{
            background: #fff;
            outline: none;
            appearance: normal;
            transition: .3s;
          }
          .-btn {
            font-size: tos(32);
            width: tos(188);
            display: inline-block;
            outline: none;

            &.-fill {
              &[type*='submit'] {
                transition: .3s;
                &:hover {
                  background-color: darken(#57b6d3, 20%);
                }
              }
              background-color: #57b6d3;
              color: #fff;
            }
            display: inline-block;
            height: tos(56);
            border-radius: tos(56);
            line-height: tos(56);
            padding: 0 tos(20);
            border: 1px solid #57b6d3;
          }
        }

      }
      &--title {
        color: #6e6e6e;
        font-size: tos(44);
        margin-bottom: tos(24);
        font-weight: bold;
      }

      &__context {
        color: #626262;
        font-size: tos(30);
        padding: tos(30) 0;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: tos(80);
        }
        span {
          display: inline-block;
          margin-bottom: tos(20);
        }
      }
    }
  }
}









